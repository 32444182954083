import React from "react"
import {
  graphql, navigate
} from "gatsby"
import Image from "gatsby-image"
import Link from '../components/link'
import { scale } from "../utils/typography"
import useViewportSize, { SIZES } from "../utils/hooks/useViewportSize"

import Layout from "../components/layout"
import CustomSwipe from "../components/swipe"
import SEO from "../components/seo"
import Related from "../components/related"

import css from './work-detail.css'

const getFluidImage = src => {
  const { childImageSharp = {} } = src || {};
  return childImageSharp.fluid || {};
}

const WorkDetailTemplate = ({
  data,
  location,
  pageContext
}) => {
  const viewportSize = useViewportSize()
  const isCompactView = [SIZES.XXS, SIZES.XS].includes(viewportSize) 
  const { html, frontmatter} = data.markdownRemark
  const { title, description, language, gallery, crypto} = frontmatter
  const { previous, next } = pageContext
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout 
      location={location}
      title={siteTitle} 
      locale={language}
    >
      <SEO
        title={title}
        description={description}
        lang={language}
      />
      <CustomSwipe
        onRightSwipe={() => {navigate(previous.fields.slug)}}
        onLeftSwipe={() => {navigate(next.fields.slug)}}
      >
        <div
          css={css}
          className={isCompactView ? 'compactView' : ''}
        >
          {/* Gallery */}
          <div className='work-detail__gallery'>
            {
              gallery.map(({alt, src}, index) => (
                <Image 
                  {...{
                    key: index,
                    alt,
                    title,
                    fluid: getFluidImage(src)
                  }}
                />)
              ) 
            }
          </div>
          {/* Description */}
          <div className='work-detail__description'>
            <div>
              <h1 css={scale(0.5)}>
                {title}
              </h1>
              <div dangerouslySetInnerHTML={{ __html: html }} />
              { crypto &&  <Link to={crypto}>NFT</Link>}
            </div>
          </div>
          {/* Related */}
          <div className='work-detail__related'>
             <Related {...{ previous, next }}/> 
          </div>
        </div>
      </CustomSwipe>
    </Layout>
  )
}

export default WorkDetailTemplate

export const workQuery = graphql`
  query WorkPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        language
        description
        crypto
        gallery {
          alt
          src {
            childImageSharp{
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
