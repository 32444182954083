import React from "react"
// eslint-disable-next-line
import { jsx } from '@emotion/core'
import IconViewer, { arrowLeft, arrowRight } from "../icons/" 
import { rhythm } from "../utils/typography"
import Link from "../components/link"


const Related = ({
  previous,
  next,
  css
}) => {
  return (
    <ul
      css={{
        display: `flex`,
        flexWrap: `wrap`,
        justifyContent: `space-between`,
        listStyle: `none`,
        padding: 0,
        width: rhythm(6),
        margin: 0,
        ...css,
      }}
    >
      <li>
        {previous && (
          <Link to={previous.fields.slug} rel="prev">
            <IconViewer icon={arrowLeft} height={25} />
          </Link>
        )}
      </li>
      <li>
        {next && (
          <Link to={next.fields.slug} rel="next">
            <IconViewer icon={arrowRight} height={25} />
          </Link>
        )}
      </li>
    </ul>
  )
}

export default Related