import React from "react";
import Swipe from 'react-easy-swipe';

const isHorizontalSwipe = (from, to) =>
  Math.abs(to.x - from.x) / Math.abs(to.y - from.y) > 3
const isSwippingRight = (from ,to) => 
   to.x - from.x > 0
const isSwipping = (from, to) => {
  const movementDuration = to.moment - from.moment

  return isHorizontalSwipe(from ,to) &&
  movementDuration > 100 &&
  movementDuration < 210
}


const CustomSwipe = ({children, onRightSwipe, onLeftSwipe}) => {
  let from
  let to

  return (
    <Swipe
      onSwipeStart={movement => { 
        const { pageX, pageY } = movement.changedTouches[0];
        from = {
          x: pageX,
          y: pageY,
          moment: new Date().getTime()
        }
      }}
      onSwipeEnd={movement => { 
        const { pageX, pageY } = movement.changedTouches[0];
        to = {
          x: pageX,
          y: pageY,
          moment: new Date().getTime()
        }
        
        if(!isSwipping(from, to)) {
          return 
        }
        
        if(isSwippingRight(from, to)) {
          onRightSwipe()
          return;
        }
        
        onLeftSwipe()
      }}
    >
    {children}
    </Swipe>
  )
}

export default CustomSwipe