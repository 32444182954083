import { css } from '@emotion/core' //TODO jsx needed?
import { rhythm } from "../utils/typography"

export default css`
  padding: ${rhythm(1)} ${rhythm(3 / 4)};
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100vw;

  .work-detail__description {
    display: flex;
    flex-direction: column;
    margin: ${rhythm(0.5)};
    h1 {
      margin: 0;
      text-transform: uppercase;
      font-size: ${rhythm(0.7)};
    }
    div {
      font-size: ${rhythm(0.6)};
    }
  }
  .work-detail__gallery {
    .gatsby-image-wrapper {
      margin: ${rhythm(1)} ${rhythm(0.5)};
    }
  }
  .work-detail__related {
    display: flex;
    justify-content: center;
    margin-top: ${rhythm(2)};
    margin-bottom: ${rhythm(3 / 2)};
  }
  &.compactView {
    flex-direction: column;
    .work-detail__description {
      position: relative;
      width: 100%;
      padding: 0 ${rhythm(1 / 2)};
      display: block;
    }
  }
`